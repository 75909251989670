<script>
import { required, maxLength } from "vuelidate/lib/validators";
import validationMessages from "@/components/validations";
import Monitor from "@/services/Monitor";
import Swal from "sweetalert2";

export default {
  components: { validationMessages },

  data() {
    return {
      url: "",
      description: "",
      send_alert: 1,
      showModal: false,
      filteredTenants: [],
      tenantDashboard: [] ,
      isFilteringComplete: false,
      submitted: false,
      tryingToSubmit: false,
      showLoader: false,
      csrf_token: localStorage.getItem("csrf_token"),
      error: null,
    };
  },

  validations: {
    url: {
        required,
    },
  },



  methods: {
    closeModal() {
      this.showModal = false;
    },

    async createMonitoredUrl() {
      this.tryingToSubmit = true;
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.tryingToSubmit = false;
        return;
      }

      let formData = {
        url: this.url,
        description: this.description,
        send_alert: this.send_alert
      };

      try {
        const response = await Monitor.createMonitoredUrl(formData);

        if (response.error) {
          this.failedmsg(response.error);
        } else {
          this.successmsg(response.message);
          this.closeModal();
          this.refreshData();
        }
      } catch (error) {
        this.error =  error.response.data.erro ? error.response.data.error : "Invalid URL";
        this.failedmsg(this.error);
      } finally {
        this.tryingToSubmit = false;
        this.refreshData();
      }
    },

    successmsg(message) {
      Swal.fire("Success", message, "success");
    },

    failedmsg(message) {
      Swal.fire("Error", message, "error");
    },

    refreshData() {
      this.$emit("onRefresh");
    },

    resetForm() {
      this.description = "";
      this.url = "";
      this.$v.$reset();
      this.submitted = false;
    },

  },
};
</script>

<template>
  <b-modal id="add_monitored_url" size="l" v-model="showModal" @hidden="resetForm" title="Monitored URL" title-class="font-18">
    <form @submit.prevent="createMonitoredUrl" v-if="!showLoader">
      <div class="row">
        <!--<div v-if="$v.$error" class="text-danger">Complete all fields</div>-->
        <b-card-text>
          <div class="row">
            <div class="col-sm-12">

              <b-form-group label="URL" label-for="formrow-url-input" class="mb-3">
                <b-form-input v-model.trim="url"></b-form-input>
                 <validationMessages v-if="submitted" :fieldName="'Url'" :validationName="$v.url"></validationMessages>
                 <div v-if="$v.$error" class="text-danger">URL Required</div>
              </b-form-group>


              <b-form-group label="Description" label-for="formrow-name-input" class="mb-3">
                <b-form-input v-model.trim="description"></b-form-input>
              </b-form-group>

                <b-form-group label="Send Alert" label-for="formrow-name-input" class="mb-3">
                      <input v-model="send_alert"
                            type="checkbox"
                            class="form-check-input"
                            id="send_alert"
                            true-value="1"
                            false-value="0"
                     />
                </b-form-group>

              <input type="hidden" name="csrf_token" v-model="csrf_token">
            </div>
          </div>
        </b-card-text>
      </div>
    </form>
    <div class="card-body d-flex align-items-center justify-content-center" v-else>
      <b-spinner large></b-spinner>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
      <b-button variant="primary" @click="createMonitoredUrl" :disabled="tryingToSubmit || showLoader">
        <b-spinner v-show="tryingToSubmit" small></b-spinner>Submit
      </b-button>
    </template>
  </b-modal>
</template>
